<template>
  <el-container>
    <el-header>
      <el-image
      class="wid275"
      :src="require('../images/feduplogo.png')"
      fit="fill"></el-image>
                            <el-dropdown class="position">
                                <span class="el-dropdown-link">
                                    <i class="el-icon-s-custom"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="look">个人信息</el-dropdown-item>
                                    <el-dropdown-item @click.native="outLogin">退出登录</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <nav-menu></nav-menu>
      </el-aside>
      <el-container>
        <el-main  :style="'height:'+height+'px'">
          <router-view v-if="flag"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>

import NavMenu from './navMenu.vue'
export default {
  name: 'Page',
  props: {
  },
  data(){
    return {
      height:document.body.clientHeight-95,
      flag:false
    }
  },
  created(){
    let _this = this;
      window.onresize = function(){
        _this.height =  document.body.clientHeight - 95;
      }
      setTimeout(function(){
        _this.flag = true;
      },1000);
  },
  components: {NavMenu},
  methods: {
    outLogin(){
       localStorage.setItem("Authorization", null);
       localStorage.setItem("Iad", null);
       this.$router.push("/login");
    },
    look(){
       this.$router.push("/views/personal");
    }
  }
}
</script>


<style scoped lang="scss">
  .el-header, .el-footer {
    border-bottom: solid 1px #e6e6e6;
  }
  .el-menu{
    text-align: left;
    min-height: 100%;
  }
  .el-aside {
    min-height: calc(100vh - 60px);
  }
  
  .el-main {
    background: #fff;
    padding: 0px 20px;
    margin-bottom: 30px;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  .position{
    width: 50px;
    height: 50px;
    font-size: 50px;
    position: absolute;
    right: 30px;
  }
  .wid275{
    width: 140px;
    position: absolute;
    left: 40px; 
    top: 15px;
  }
  .el-button{
    
    font-size: 16px;
    font-weight: bold;
  }
  
.el-menu--horizontal > .el-menu-item{
    font-weight: bold;
}
.el-form--inline .el-form-item__label{
    font-weight: bold;
}
</style>

import axios from 'axios'
import qs from 'qs'
import globla from '../globla.js'


//let token = localStorage.getItem('Authorization');
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.put['content-Type'] = 'application/json';
axios.defaults.baseURL = globla.SERVE_URL;//  请求服务器具体的地址
axios.defaults.withCredentials  = true; //  在跨域中允许携带凭证
//application/x-www-form-urlencoded ： <form encType=””>中默认的encType，form表单数据被编码为key/value格式发送到服务器（表单默认的提交数据的格式）
//axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';//  声明传给服务器的数据，通过请求传给服务器的数据application/x-www-form-urlencoded格式
//axios.defaults.headers.Accept = 'application/json';
//axios.defaults.headers.common["Authorization"] ='Bearer '+ token; //  携带token请求头
  //  axios.defaults.headers = {
  //   'Content-Type': 'application/x-www-form-urlencoded',
  //   'Accept': 'application/json',
  // }
 // axios.defaults.timeout = 10000;
// 请求拦截器：在发送请求前拦截
axios.defaults.timeout = 10000;
axios.interceptors.request.use(config => {
  let token = localStorage.getItem('Authorization');
  if(token){
    config.headers.common['Authorization'] ='Bearer '+token;
   // config.headers.Authorization ='Bearer '+token;
  }
  
  if (config.method === 'post' && config.headers['Content-Type'] != 'multipart/form-data') {
    config.data = qs.stringify(config.data)
  }
  return config;
}, error => {
  return Promise.reject(error)
})

// 响应拦截器：在请求响应之后拦截
axios.interceptors.response.use(response => {
  return response;
}, error => {
  // if(error.response.code == 402 && error.response.message == '没有权限'){//没有权限的场景一
  //   this.$message.error(error.response.message);
  // }
  // if(error.response.code == 401 && error.response.message == 'Unauthorized'){//没有权限的场景二
  //   this.$message.error(error.response.message);
  // }
  return Promise.reject(error)
})

let post;

  post = (url, params) => {//"datatype":"json"
    
      return new Promise((resolve, reject) => {
          axios({
              method: 'post',
              url,
              data: params
          }).then(res => {
              resolve(res.data)
          }).catch((errer) => {
              reject(errer)
          })
      })
  
  }
  
export default post;

const config={
    waitItem:'api/community/waitItem',//待审核内容饼图
    interaction:'api/community/interaction',//单日互动量
    contentRelease:'api/community/contentRelease',//单日发布内容数
    dayActiveRate:'api/community/dayActiveRate',//日活跃率
    peopleGrowth:'api/community/peopleGrowth',//社区使用人数
    mgExport:'api/community/mgExport',//导出
    consumerExport:'api/community/consumerExport',//用户导出
    mg:'api/community/mg',//敏感词详情 编辑
    mgList:'api/community/mgList',//敏感词列表
    interestUpload:'api/community/interestUpload',//兴趣图片上传
    interestChangeStatus:'api/community/interestChangeStatus',//兴趣状态变更
    interestInfo:'api/community/interestInfo',//兴趣详情
    interest:'api/community/interest',//兴趣列表
    homeMenuInfo:'api/community/homeMenuInfo',//图片上传
    homeMenuUpload:'api/community/homeMenuUpload',//图片上传
    homeMenuChangeStatus:'api/community/homeMenuChangeStatus',//菜单列表
    homeMenu:'api/community/homeMenu',//菜单列表
    bindOrgList:'api/community/bindOrgList',//回显
    bindMenuList:'api/community/bindMenuList',//角色绑定菜单信息
    authenticationInfo:'api/community/authenticationInfo',//认证状态变更
    authenticationChangeStatus:'api/community/authenticationChangeStatus',//认证状态变更
    userSearch:'api/community/userSearch',//用户搜索
    selectauthentication:'api/community/select/authentication',//认证类型
    authentication:'api/community/authentication',//认证列表
    consumerInfo:'api/community/consumerInfo',//用户详情
    consumer:'api/community/consumer',//用户列表
    reportInfo:'api/community/reportInfo',//反馈详情
    report:'api/community/report',//反馈列表
    selectreport:'api/community/select/report',//反馈问题下拉框
    articleExamineLog:'api/community/articleExamineLog',//内容审核记录
    articleExamineInfo:'api/community/articleExamineInfo',//审核详情
    articleExamine:'api/community/articleExamine',//内容审核列表
    articleInfo:'api/community/articleInfo',//内容详情
    article:'api/community/article',//内容列表
    contryNumberSelect:'api/common/contryNumberSelect',//国家区号

    articleImageUpload:'api/community/articleImageUpload',//上传内容图片
    uploadHeadimg:'api/community/uploadHeadimg',//上传头像
    consumerAdd:'api/community/consumerAdd',//上传头像
    consumerEdit:'api/community/consumerEdit',//编辑用户
    articlePush:'api/community/articlePush',//发布内容
    articlePushDraftAdd:'api/community/articlePushDraftAdd',//添加草稿
    articlePushDraftList:'api/community/articlePushDraftList',//草稿列表
    articlePushDraftInfo:'api/community/articlePushDraftInfo',//草稿详情
    articlePushDraftDelete:'api/community/articlePushDraftDelete',//草稿删除
    register:'api/user/register',//草稿编辑
    articlePushList:'api/community/articlePushList',//发布内容列表
    articlePushInfo:'api/community/articlePushInfo',//详情
    articleDelete:'api/community/articleDelete',//删除文章
    place:'api/community/place',//定位
    addTopic:'api/community/addTopic',//添加话题
    topicList:'api/community/topicList',//话题列表
    articleCategory:'api/community/articleCategory',//内容分类
    articlePushUserList:'api/community/articlePushUserList',//获取用户列表私人发布
    aircompany:'api/community/aircompany',//航司列表
    articlePushEdit:'api/community/articlePushEdit',//编辑内容
    articlePushDraftEdit:'api/community/articlePushDraftEdit',//草稿编辑

    topicInfo:'api/community/topicInfo',//话题详情
    topic:'api/community/topic',//话题列表
    topicStatus:'api/community/topicStatus',//话题状态修改
    labelStatus:'api/community/labelStatus',//内容标签状态修改
    labelInfo:'api/community/labelInfo',//内容标签详情
    label:'api/community/label',//内容标签列表
    selecttopicCategory:'api/community/select/topicCategory',//话题下拉框
    selectlabelCategory:'api/community/select/labelCategory',//内容下拉框
    topicCategoryInfo:'api/community/topicCategoryInfo',//话题分类状态变更
    topicCategoryStatus:'api/community/topicCategoryStatus',//话题分类状态变更
    topicCategory:'api/community/topicCategory',//话题分类列表
    labelCategoryStatus:'api/community/labelCategoryStatus',//内容标签分类状态变更
    labelCategoryInfo:'api/community/labelCategoryInfo',//内容分类标签详情
    labelCategory:'api/community/labelCategory',//内容分类标签列表
    descritionExamine:'api/community/descritionExamine',//昵称审核列表
    nicknameExamine:'api/community/nicknameExamine',//昵称审核列表
    headExamine:'api/community/headExamine',//评论审核记录
    commentLog:'api/community/commentLog',//评论审核记录
    bindOrg:'api/community/bindOrg',//为菜单绑定接口
    commentExamineInfo:'api/community/commentExamineInfo',//审核详情
    commentExamine:'api/community/commentExamine',//评论列表
    routeChangeStatus:'api/community/routeChangeStatus',//变更路由状态
    routeInfo:'api/community/routeInfo',//路由详情
    route:'api/community/route',//路由列表
    userInfoSelf:'api/community/userInfoSelf',//个人信息
    userInfo:'api/community/userInfo',//用户详情
    jur:'api/community/jur',//获取用户菜单权限
    userChangeStatus:'api/community/userChangeStatus',//变更用户状态
    userInfoEdit:'api/community/userInfoEdit',//个人信息修改
    user:'api/community/user',//用户列表
    roleChangeStatus:'api/community/roleChangeStatus',//变更角色状态
    roleInfo:'api/community/roleInfo',//获取角色详情
    bindMenu:'api/community/bindMenu',//角色绑定菜单
    role:'api/community/role',//角色列表
    menuChangeStatus:'api/community/menuChangeStatus',//变更菜单状态
    menu:'api/community/menu',//菜单列表
    login:'api/community/login',//登录
};

export default config;
<template>
   <el-menu
      :default-active="index"
      active-text-color="teal"
      class="el-menu-vertical-demo"
      :unique-opened='true'
      @open="handleOpen"
      @close="handleClose">
      <div v-for="item in list" :key="item.menu_id">
        <el-menu-item  v-if="!item.has" v-show="item.is_show == 1" :index="item.url+''"  @click="goNav('/')">
          <i class="el-icon-menu"></i>
          <span :class="fatherid==item.menu_id?'spantitle':''" slot="title">{{item.name}}</span>
        </el-menu-item>

        <el-submenu v-if="item.has" v-show="item.is_show == 1" :index="item.menu_id+''">
          <span :class="fatherid==item.menu_id?'spantitle':''" slot="title"><i :class="item.icon"></i>{{item.name}}</span>

          <div v-for="ite in item._child" :key="ite.menu_id">
            <el-menu-item v-if="!ite.has" v-show="ite.is_show == 1" :index="ite.url+''" @click="goNav(ite.url)" >{{ite.name}}</el-menu-item>

            <el-submenu v-if="ite.has" v-show="ite.is_show == 1" :index="ite.menu_id+''">
              <span :class="childid==ite.menu_id?'spantitle2':''" slot="title">{{ite.name}}</span>
              <div v-for="it in ite._child" :key="it.menu_id">

                <el-menu-item v-if="!it.has" v-show="it.is_show == 1" :index="it.url+''" @click="goNav(it.url)" >{{it.name}}</el-menu-item>
                <el-submenu v-if="it.has" v-show="it.is_show == 1" :index="it.menu_id+''">
                  <span :class="childid==ite.menu_id?'spantitle2':''" slot="title">{{it.name}}</span>
                    <div v-for="i in it._child" :key="i.menu_id">

                    <el-menu-item v-if="!i.has" v-show="i.is_show == 1" :index="i.url+''" @click="goNav(i.url)" >{{i.name}}</el-menu-item>
                    <el-submenu v-if="i.has" v-show="i.is_show == 1" :index="i.menu_id+''">
                      <span  slot="title">{{i.name}}</span>
                        <div v-for="j in i._child" :key="j.menu_id">

                          <el-menu-item v-if="!j.has" v-show="j.is_show == 1" :index="j.url+''" @click="goNav(j.url)" >{{j.name}}</el-menu-item>
                          <el-submenu v-if="j.has" v-show="j.is_show == 1" :index="j.menu_id+''">
                            <span  slot="title">{{j.name}}</span>
                          </el-submenu>

                        </div>
                    </el-submenu>

                  </div>
                </el-submenu>

              </div>
            </el-submenu>

          </div>

        </el-submenu>

      </div>
    </el-menu>
</template>

<script>
import axios from 'axios'
import config from '../lib/config'
export default {
  name: 'NavMenu',
  props: {
  },
  data(){
    return {
      list:[],
      index:'views/logo',
      navlist:[],
      fatherid:13,
      childid:0,
    }
  },
  methods: {
      handleOpen(key, keyPath) {
        if(keyPath.length > 1){
          this.childid = keyPath[1]
          this.fatherid = keyPath[0]
        }else{
          this.fatherid = key
          this.childid = 0
        }
        console.log( keyPath)
      },
      handleClose(key, keyPath) {
        if(keyPath.length > 1){
          this.childid = keyPath[1]
          this.fatherid = keyPath[0]
        }else{
          this.fatherid = key
          this.childid = 0
        }
      },
      isshowbtn(list,url){
        console.log(list,url);
        if(localStorage.getItem('Iad') == 1){
          location.add = 1;
          location.edit = 1;
          location.detail = 1;
          location.delete = 1;
          location.bind = 1;
        }else{
          location.add = 0;
          location.edit = 0;
          location.delete = 0;
          location.bind = 0;
          location.detail = 0;
        }
        
        zsgc(list);
        function zsgc(list){
          for(let i in list){
            if(list[i]._child){
              if(list[i].url == url){
                add(list[i]._child)
              }else{
                zsgc(list[i]._child);
              }
            }
          }
        }
        function add(list){
          for(let i in list){
            if(list[i].url == 'add'){//增
              location.add = 1;
            }
            if(list[i].url == 'delete'){//变更
              location.delete = 1;
            }
            if(list[i].url == 'edit'){//操作
              location.edit = 1;
            }
            if(list[i].url == 'bind'){//绑定
              location.bind = 1;
            }
            if(list[i].url == 'detail'){//详情
              location.detail = 1;
            }
          }
        }
      },
      goNav(url){
        if(url == '/'){
          this.fatherid = 13
          this.childid = 0
          if(location.pathname == '/views/logo'){
          return;
          }
        }
        
        let list = this.list;
        if(url != location.pathname){
          this.$router.push(url)
        }
        console.log(1);
        this.isshowbtn(list,url);
        
        sessionStorage.setItem("pathname", url);//点击左侧导航栏的时候覆盖左侧导航栏session里面的pathname

        sessionStorage.setItem("formInline", '');
      },
      init(){
        let _this = this;
        axios.get(config.jur)
        .then(function (response) {
            if(response.data.code == 200){
              _this.list = response.data.data;
              _this.geturl();//刷新之后左边侧边栏 恢复
              _this.homepage();
            }else{
                  localStorage.setItem("Authorization", null);
                  localStorage.setItem("Iad", null);
                  _this.$router.push("/login");
            }
          })
        .catch(function (error) {
                     localStorage.setItem("Authorization", null);
                      localStorage.setItem("Iad", null);
                      _this.$router.push("/login");
          console.log(error);
        });

      },
      homepage(){//首页展示权限
        let _this = this;
        let  list = _this.list;
        location.examine = 0;
        location.operates = 0;
        for(let i in list){
          if(list[i].url == 'examine'){
            location.examine = 1;
          }
          if(list[i].url == 'operates'){
            location.operates = 1;
          }
        }
      },
      geturl(){//侧边栏恢复 优先取session存储的url
        let _this = this; 
        let url = sessionStorage.getItem('pathname')?sessionStorage.getItem('pathname'):location.pathname;
        let list = _this.list;
        _this.isshowbtn(list,url);
        _this.index = url;
        dglist(list)
        function dglist(list){
          for(let i in list){
            if(list[i].url == url){
              _this.childid = list[i].p_id;
            }else{
              if(list[i]._child){
                dglist(list[i]._child);
              }
            }
          }
        }
        dgpid(list);
        function dgpid(list){
          for(let i in list){
            if(list[i].menu_id == _this.childid){
              _this.fatherid = list[i].p_id;
            }else{
              if(list[i]._child){
                dgpid(list[i]._child);
              }
            }
          }
        }
     },
  },
  created(){
    let _this = this;
      _this.init();
  }
}

</script>

<style scoped lang="scss">
.spantitle{
    font-size: 18px;
    font-weight: bold;
    color: teal;
}
.spantitle2{
    font-size: 16px;
    font-weight: bold;
    color: teal;
}
</style>

// 是否开启本生产环境
let environment = false;

// 服务器请求地址
let SERVE_URL = "";

if(environment){
    // 生产环境
    SERVE_URL = "http://api.community.fedup.cn";
}else{
    // 开发环境
    SERVE_URL = "http://api.communitytest.fedup.cn";
}
export default {
    SERVE_URL
}